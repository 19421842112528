import React, { useEffect, useState } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import * as S from './styles'
import { CAREERS_URL, getWWWBaseUrl } from './utils'

const WWW_BASE_URL = getWWWBaseUrl()
const NMLS_NUMBER = '2129447'

const indexRouteDisclaimers = (
  <>
    <React.Fragment key="credits">
      <a href={'https://flickr.com/photos/danielsphotography/466427534'}>
        Photo from Flickr
      </a>
      , used under{' '}
      <a href={'https://creativecommons.org/licenses/by/2.0/'}>CC license</a>
    </React.Fragment>
  </>
)

const Footer: React.FC<{ displayNavigationLinks?: boolean }> = ({
  displayNavigationLinks = true,
}) => {
  const [loadAdditionalDisclaimers, setLoadAdditionalDisclaimers] =
    useState(false)

  useEffect(() => {
    //Doing this in an Effect to keep this component SSR-friendly
    if (window.location.href == `${getWWWBaseUrl()}/`) {
      setLoadAdditionalDisclaimers(true)
    }
  }, [loadAdditionalDisclaimers])

  return (
    <S.Footer>
      <S.FooterTop>
        <S.FooterTopLeft>
          <S.TitleSection>
            <S.TitleHeader>Custodia</S.TitleHeader>
            <S.NMLS>NMLS # {NMLS_NUMBER}</S.NMLS>
            <S.InfoLink href={'mailto: info@custodiabank.com'}>
              <span>info@custodiabank.com</span>
            </S.InfoLink>
            <S.DBAParagraph>
              In certain US states, Custodia Bank, Inc. operates as{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${WWW_BASE_URL}/dba`}
              >
                <S.DBALink>Custodia Digital Services, Inc.</S.DBALink>
              </a>
            </S.DBAParagraph>
            <S.DBAParagraph>
              Custodia Bank, Inc. is not recognized as a bank in Maryland
            </S.DBAParagraph>
          </S.TitleSection>
          <S.MountainWrapper>
            <S.MountainImage
              alt={'colorful mountain range'}
              src={`${WWW_BASE_URL}/images/footer-mountain-outline.png`}
            />
            <S.MadeInWyomingHeader>Made in Wyoming</S.MadeInWyomingHeader>
          </S.MountainWrapper>
        </S.FooterTopLeft>
        {displayNavigationLinks && (
          <S.NavigationSection>
            <S.NavigationHeader>Navigation</S.NavigationHeader>
            <S.NavigationList>
              <NavigationItem href={`${WWW_BASE_URL}/press`} title={'Press'} />
              <NavigationItem href={`${WWW_BASE_URL}/about`} title={'About'} />
              <NavigationItem
                href={CAREERS_URL}
                title={'Careers'}
                target={'_blank'}
              />
            </S.NavigationList>
          </S.NavigationSection>
        )}
      </S.FooterTop>
      <S.FooterBottom>
        <S.DisclaimerSection>
          <S.DisclaimerHeader>Disclaimer</S.DisclaimerHeader>
          <S.DisclaimerParagraph>
            Some services not currently available. All services, products, and
            access subject to applicable BSA/AML and other screening and
            documentary requirements.
            <br />
            <br />
            Information provided on this website does not, and is not intended
            to, constitute tax or legal advice. You should consult your own tax,
            legal and accounting advisors before using any of Custodia Bank,
            Inc.&apos;s products or services.
          </S.DisclaimerParagraph>
          <S.DisclaimerParagraph>
            To report a bug or vulnerability please contact{' '}
            <a href={'mailto: bugreport@custodiabank.com'}>
              bugreport@custodiabank.com
            </a>
            .
          </S.DisclaimerParagraph>
          {loadAdditionalDisclaimers && (
            <S.DisclaimerParagraph>
              {indexRouteDisclaimers}
            </S.DisclaimerParagraph>
          )}
        </S.DisclaimerSection>
        <NotFdicInsuredDisclosure />
        <BottomCopyrightLine />
      </S.FooterBottom>
      <S.BottomBorder />
    </S.Footer>
  )
}

export default Footer

export const NotFdicInsuredDisclosure = () => (
  <S.NotFdicInsuredDisclosure>Not FDIC Insured</S.NotFdicInsuredDisclosure>
)
export const MmfDisclosure = ({ onClick }: { onClick: () => void }) => (
  <S.MmfDisclosure onClick={onClick}>
    <span>
      DEPOSITS AND INVESTMENTS ARE NOT GUARANTEED BY ANY FEDERAL OR STATE
      GOVERNMENTAL AGENCY AND MAY LOSE VALUE
    </span>
    <MdOpenInNew />
  </S.MmfDisclosure>
)

export const BottomCopyrightLine = () => (
  <S.BottomCopyrightLine>
    <S.CopyrightLineGrouping>
      <a
        href={`${WWW_BASE_URL}/legal/terms-of-service`}
        target={'_blank'}
        rel={'noreferrer noopener'}
      >
        Terms of Service
      </a>
      <span>|</span>
      <a
        href={`${WWW_BASE_URL}/licenses-and-disclosures`}
        target={'_blank'}
        rel={'noreferrer noopener'}
      >
        Licenses and Disclosures
      </a>
      <span>|</span>
      <a
        href={`${WWW_BASE_URL}/legal/privacy-notice`}
        target={'_blank'}
        rel={'noreferrer noopener'}
      >
        Privacy
      </a>
    </S.CopyrightLineGrouping>
    <S.CopyrightLineGrouping>
      <span>© {new Date().getFullYear()} Custodia Bank, Inc.</span>
      <span>|</span>
      <span>All Rights Reserved.</span>
    </S.CopyrightLineGrouping>
  </S.BottomCopyrightLine>
)

interface NavigationItemProps {
  href: string
  title: string
  target?: string
}

const NavigationItem = ({
  href,
  title,
  target = null,
}: NavigationItemProps) => (
  <li>
    <a target={target} href={href}>
      {title}
    </a>
  </li>
)
